import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Sticky from 'react-stickynode';
import get from 'lodash/get';
import FCLink from '../fc_link/fc_link';
import AdContainer from '../ad/AdContainer';

function processAssetServerImage(imgSrc) {
  if (typeof imgSrc !== 'string') {
    return '';
  }

  return imgSrc.replace(
    'multisite_files/fastcompany',
    'multisite_files/fastcompany/imagecache/w/80/h/80/scale'
  );
}

export default class MCPSection extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { config, index, data } = this.props;

    return (
      <section className={`mcp-section mcp-section--${index}`}>
        <div className={`mcp-section__row mcp-section__row--${index}`}>
          {data
            ? data.map(person => {
                const hasThumbnail = !!get(person, 'thumb_url');
                return (
                  <FCLink
                    config={config}
                    to={`https://www.fastcompany.com/person/${get(
                      person,
                      'url'
                    )}`}
                    title={`${get(person, 'first_name')} ${get(
                      person,
                      'last_name'
                    )}`}
                    key={get(person, 'id')}
                  >
                    {hasThumbnail ? (
                      <div className="mcp-section__poster">
                        <img
                          className="mcp-section__poster-image"
                          src={processAssetServerImage(
                            get(person, 'thumb_url')
                          )}
                          alt={`${get(person, 'first_name')} ${get(
                            person,
                            'last_name'
                          )}`}
                        />
                      </div>
                    ) : (
                      ''
                    )}

                    <div
                      className={`mcp-section__text ${
                        hasThumbnail ? '' : 'mcp-section__text--no-thumb'
                      }`}
                    >
                      <h2 className="mcp-section__name">
                        {/* eslint-disable-next-line max-len */}
                        {`${
                          get(person, 'ranking') < 10
                            ? `0${get(person, 'ranking')}`
                            : get(person, 'ranking')
                        }`}
                        {`  ${get(person, 'first_name')} ${get(
                          person,
                          'last_name'
                        )}`}
                      </h2>
                      <span className="mcp-section__reason">
                        {get(person, 'why')}
                      </span>
                    </div>
                  </FCLink>
                );
              })
            : ''}
        </div>

        <div className="all-feed__sidebar">
          <div className="all-feed__promotion">
            <Sticky top={100} bottomBoundary={`.mcp-section__row--${index}`}>
              <AdContainer type="rail_sticky" />
            </Sticky>
          </div>
        </div>

        <div className="mcp-section__all-ad-container">
          <AdContainer type="break" />
          <AdContainer type="in_content" />
        </div>
      </section>
    );
  }
}

MCPSection.propTypes = {
  config: PropTypes.shape({}).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  index: PropTypes.string.isRequired
};
